import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core'
import {animate, state, style, transition, trigger} from '@angular/animations'
import {faMinus} from '@fortawesome/pro-solid-svg-icons/faMinus'
import {faPlus} from '@fortawesome/pro-solid-svg-icons/faPlus'

const EXPANSION_PANEL_ANIMATION_TIMING = '200ms cubic-bezier(0.4, 0, 0.2, 1)'

@Component({
  selector: 'cft-accordion-element',
  templateUrl: './accordion-element.component.html',
  styleUrls: ['./accordion-element.component.css'],
  animations: [
    /** Animation that expands and collapses the panel content. */
    trigger('bodyExpansion', [
      state('collapsed, void', style({height: '0px', visibility: 'hidden'})),
      state('expanded', style({height: '*', visibility: 'visible'})),
      transition('expanded <=> collapsed, void => collapsed', animate(EXPANSION_PANEL_ANIMATION_TIMING)),
    ]),
  ],
})
export class AccordionElementComponent {
  @Input() letter!: string
  @Input() title!: string
  @Input()
  @HostBinding('class.closed')
  isCollapsed = true

  @Input() whitespace: 'default' | 'sm' = 'default'

  @Input()
  @HostBinding('class')
  type: 'flat' | 'outlined' | 'elevated' = 'outlined'

  @Output() activated = new EventEmitter<boolean>()

  readonly openIcon = faPlus
  readonly closeIcon = faMinus

  toggleContent(): void {
    this.isCollapsed = !this.isCollapsed
    if (!this.isCollapsed) this.activated.emit(true)
  }

  open(): void {
    this.isCollapsed = false
  }

  close(): void {
    this.isCollapsed = true
  }
}
