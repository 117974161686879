<div
  class="element-header flex cursor-pointer flex-row items-center"
  [ngClass]="{
    'py-xl': whitespace === 'default',
    'px-lg': whitespace === 'default' && type !== 'flat',
    'px-md': (whitespace === 'default' && type === 'flat') || (whitespace === 'sm' && type !== 'flat'),
    'py-sm': whitespace === 'sm',
    'px-xs': whitespace === 'sm' && type === 'flat'
  }"
  (click)="toggleContent()"
>
  @if (letter) {
    <div class="rounded-full border border-president-500 p-[2px]">
      <div
        class="flex h-4 w-4 items-center justify-center rounded-full bg-president-500 align-middle text-white heading-md"
      >
        {{ letter | uppercase | slice: 0 : 1 }}
      </div>
    </div>
  }
  <div class="text-md font-semibold" [class.ml-sm]="letter">{{ title }}</div>
  <div
    class="element-icon ml-auto flex h-4.5 w-4.5 shrink-0 items-center justify-center rounded-full border border-transparent text-md font-semibold"
    [ngClass]="{
      'bg-grey-50': isCollapsed,
      'bg-grey-700 text-white': !isCollapsed
    }"
  >
    <fa-icon [icon]="isCollapsed ? openIcon : closeIcon"></fa-icon>
  </div>
</div>
<div content="body" class="overflow-hidden" [@bodyExpansion]="isCollapsed ? 'collapsed' : 'expanded'">
  <div
    [ngClass]="{
      'pb-lg': whitespace === 'default',
      'px-lg': whitespace === 'default' && type !== 'flat',
      'px-md': (whitespace === 'default' && type === 'flat') || (whitespace === 'sm' && type !== 'flat'),
      'pb-md': whitespace === 'sm',
      'px-xs': whitespace === 'sm' && type === 'flat'
    }"
  >
    <ng-content></ng-content>
  </div>
  <ng-content select="cft-accordion-element-footer"></ng-content>
</div>
