import {AfterViewInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList} from '@angular/core'
import {AccordionElementComponent} from './accordion-element/accordion-element.component'
import {Observable, Subject} from 'rxjs'
import {takeUntil} from 'rxjs/operators'

@Component({
  selector: 'cft-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css'],
})
export class AccordionComponent implements AfterViewInit {
  @ContentChildren(AccordionElementComponent) items!: QueryList<AccordionElementComponent>

  @Input() activeIndexAt$?: Observable<number>

  @Output() activated = new EventEmitter<number>()

  private readonly destroy$ = new Subject<true>()

  ngAfterViewInit() {
    this.activeIndexAt$?.pipe(takeUntil(this.destroy$)).subscribe(index => {
      this.items.forEach((item, i) => {
        if (i === index) {
          item.open()
          return
        }
        item.close()
      })
    })

    this.items?.map((item, index) => {
      item.activated.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.items.forEach((item, i) => {
          if (i !== index) item.isCollapsed = true
        })
        this.activated.emit(index)
      })
    })
  }
}
