import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {AccordionElementComponent} from './accordion-element/accordion-element.component'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import {AccordionComponent} from './accordion.component'
import {AccordionElementFooterComponent} from './accordion-element-footer/accordion-element-footer.component'

@NgModule({
  declarations: [AccordionComponent, AccordionElementComponent, AccordionElementFooterComponent],
  imports: [CommonModule, FontAwesomeModule],
  exports: [AccordionComponent, AccordionElementComponent, AccordionElementFooterComponent],
})
export class AccordionModule {}
