import {Component, ChangeDetectionStrategy} from '@angular/core'

@Component({
  selector: 'cft-accordion-element-footer',
  templateUrl: './accordion-element-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        @apply flex justify-end gap-sm border-t border-grey-400 px-md py-sm;
      }
      :host-context(.flat) {
        @apply border-transparent;
      }
    `,
  ],
})
export class AccordionElementFooterComponent {}
